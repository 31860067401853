import styled from "styled-components"
import { Button } from "react-bootstrap"

const StyledButton = styled(Button)`
  border-radius: 15px;
  letter-spacing: ${props => (props.component === "header" ? null : "2px")};
  color: ${props =>
    props.location === "footer"
      ? "var(--header-link-color)"
      : props.location === "Address"
      ? "var(--header-link-color)"
      : props.component === "header"
      ? "var(--header-link-color)"
      : "var(--page-link-color)"} !important;
  border-color: ${props =>
    props.location === "footer"
      ? "var(--footer-text-color)"
      : props.location === "Address"
      ? "var(--page-link-color)"
      : props.component === "header"
      ? "var(--header-link-color)"
      : "var(--page-link-color)"};
  background: ${props =>
    props.location === "footer"
      ? "var(--secondary-color)"
      : "var(--secondary-color)"};
  margin-bottom: ${props => (props.component === "header" ? null : "2rem")};
  text-decoration: none !important;

  &:focus {
    /* Same as neutral */
    color: ${props =>
      props.location === "footer"
        ? "var(--header-link-color)"
        : props.location === "Address"
        ? "var(--header-link-color)"
        : props.component === "header"
        ? "var(--header-link-color)"
        : "var(--page-link-color)"} !important;
    background: ${props =>
      props.location === "footer" ? "var(--secondary-color)" : "transparent"};
    border-color: ${props =>
      props.location === "footer"
        ? "var(--footer-text-color)"
        : props.location === "Address"
        ? "var(--page-link-color)"
        : props.component === "header"
        ? "var(--header-link-color)"
        : "var(--page-link-color)"};
    /* End same as neutral */

    box-shadow: none !important;
  }

  &:visited {
    color: ${props =>
      props.location === "footer"
        ? "var(--header-link-color)"
        : props.location === "Address"
        ? "var(--header-link-color)"
        : props.component === "header"
        ? "var(--header-link-color)"
        : "var(--page-link-color)"} !important;
  }

  &:hover,
  &:active {
    color: ${props =>
      props.location === "footer"
        ? "var(--footer-bg-color)"
        : props.location === "Address"
        ? "var(--header-link-color)"
        : props.component === "header"
        ? "var(--header-bg-color)"
        : "var(--page-bg-color)"} !important;

    border-color: ${props =>
      props.location === "footer"
        ? "var(--footer-bg-color)"
        : props.location === "Address"
        ? "var(--page-bg-color)"
        : "var(--page-bg-color)"};

    background-color: ${props =>
      props.location === "footer"
        ? "var(--page-link-hover-color)"
        : props.location === "Address"
        ? "var(--page-text-color)"
        : props.component === "header"
        ? "var(--header-link-color)"
        : "var(--page-text-color)"};
    transition-duration: 0.4s;
  }
`

export default StyledButton
